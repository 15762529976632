$softorange: #f4a259;
$tomatored: #f25c66;

.close-container {
  //   position: absolute;
  margin: auto;
  width: 50px;
  height: 50px;
  //   margin-top: 100px;
  cursor: pointer;
}

.leftright {
  height: 4px;
  width: 50px;
  position: absolute;
  margin-top: 24px;
  background-color: $softorange;
  border-radius: 2px;
  transform: rotate(45deg);
  transition: all 0.3s ease-in;
}

.rightleft {
  height: 4px;
  width: 50px;
  position: absolute;
  margin-top: 24px;
  background-color: $softorange;
  border-radius: 2px;
  transform: rotate(-45deg);
  transition: all 0.3s ease-in;
}

// label {
//   color: black;
//   font-size: 0.6em;
//   text-transform: uppercase;
//   text-align: center;
//   letter-spacing: 2px;
//   transition: all 0.3s ease-in;
//   opacity: 0;
// }
.close {
  margin: 60px 0 0 5px;
  position: absolute;
}

.close-container:hover .leftright {
  transform: rotate(-45deg);
  background-color: $tomatored;
}
.close-container:hover .rightleft {
  transform: rotate(45deg);
  background-color: $tomatored;
}
.close-container:hover label {
  opacity: 1;
}
