.links-episode {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
}

#titre-episode {
  position: absolute;
  z-index: 10;
  top: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  transition: 0.5s ease;
}
.content {
  width: 100%;
  padding-top: 10px;
  text-align: center;
  background: #ffffffb9;
  color: rgb(99, 99, 99);
  padding: 10px 15px;
  border-radius: 5px;
  pointer-events: none;
  font-family: "Changa", sans-serif;
}

.presentation {
  position: absolute;
  width: 100%;
  // min-height: 80vh;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  overflow-y: scroll;
  justify-content: center;
  z-index: 50;
  // align-items: center;
  background-color: rgba(255, 255, 255, 0.921);
  cursor: auto;
  .pres-text {
    // overflow: scroll;
    padding: 0 3vw 3vw 3vw;
    width: 100%;
    height: auto;
    max-width: 700px;
    margin: 30px 10px 10px 5px;
    p {
      white-space: pre-line;
    }

    h1 {
      padding-bottom: 20px;
    }
  }
}

.toggle {
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 100;
}

.not-visible {
  opacity: 0;
  pointer-events: none;
}

.visible {
  opacity: 1;
}

.about-general {
  transition: 0.5s ease;
}

// .episode {
//   .content {
//     padding-top: 5px;
//   }
// }
