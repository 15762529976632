@import "./exemple";
@import "./apppage";
@import "./close";

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

.canvas {
  width: 100vw;
  height: 100vh;
}

canvas:active {
  cursor: grabbing;
}

canvas {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  font-family: "Outfit", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4;
  font-size: 23px;
}
audio {
  padding: 20px 0 20px 0;
}

.overlay-wrapper {
  z-index: 10 !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.overlay {
  pointer-events: none;
  width: 150px;
  z-index: 10;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

img {
  width: 100%;
  height: auto;
}

h1 {
  font-family: "Changa", sans-serif;
  font-size: 60px;
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 40px;
  }

  body {
    font-size: 20px;
  }
}

.loader {
  width: 100vw;
  height: 100vh;
  background-color: #fcfff1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: auto;
  flex-direction: column;
  .loader-wrapper {
    width: 150px;
    height: 8px;
    border-radius: 25px;
    background-color: rgb(202, 202, 202);
    p {
      text-align: center;
    }
  }

  .load {
    background-color: rgba(0, 0, 0, 0.533);
    border-radius: 25px;
  }
}
